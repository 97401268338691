import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from 'v-click-outside'
import router from './router/index'
import Scrollspy from 'vue2-scrollspy'
import VueSweetalert2 from 'vue-sweetalert2'

import '@/design/index.scss'

import store from '@/state/store'

import App from './App.vue'
import i18n from './i18n'

import VueToast from 'vue-toast-notification'
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueRouter)
Vue.use(vco)
Vue.use(Scrollspy)
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(VueToast)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
