// import store from '@/state/store'

export default [
  {
    path: '/:id',
    name: 'default',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/jobs/detail'),
  },
  {
    path: '*',
    redirect: 'default',
  },
]
